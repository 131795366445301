$light-gray: #e5e5e5;
$dark-gray: #888;

$link-color: #428bca;
$default-rule: 1px solid $light-gray;

$base-font-size: 14px;

@mixin columns($n) {
    -webkit-column-count: $n;
    -moz-column-count: $n;
    column-count: $n;
}

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: $base-font-size;
  font-weight: 400;
}

a:hover {
    text-decoration: none; // don't underline links on hover
}

h2 {
    font-size: 2rem;
    margin-top: 1em;
}

h3 {
    font-size: 1.5rem;
    margin-top: 1em;
}

h4 {
    font-size: 1.1rem;
    font-weight: bold;
}

a {
  color: $link-color;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: $default-rule;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: $default-rule;
  text-align: center;
  font-size: 0.8em;
}
.footer p {
  margin: 0.1em 0;
}
.footer a:link, .footer a:visited {
  color: #777;
}

.card {
    
    p {
        line-height: 1.4em;
        margin-bottom: 0rem;
    }
    
    a {
        color: black;
    }
    
    a:hover {
        text-decoration: none;
    }
    
    .title {
        display: table;
        .card-title {
            display: table-cell;
            vertical-align: middle;
            margin: 0em .5rem;
        }
    }
    
    .card-title {
        // undo extra margin set above for normal headings elsewhere
        h3 { margin-top: 0em; }
        padding-bottom: .5rem;
    }
    
    .card-block {
        padding: 1rem;
    }
    
    img.icon {
        max-height: 2em;
        margin: 0em 0.5rem;
    }
}


//ul.people {
div.people {
    
    list-style-type: none;
    padding-left: 0em;
    
    $profile-size: 100px;

    .profile {
        width: $profile-size;
        height: $profile-size;
    }

    //li {
  
        height: auto;
        display: table;
        margin: 0rem;
        
        .profile {
            text-align: center;
            display: table-cell;
            margin: .5rem;
            vertical-align: middle;
        }
        
        .person {
            display: table-cell;
            vertical-align: middle;
            width: auto;
        }

        .bio {
            color: $dark-gray;
            font-size: $base-font-size - 2;
        }

        .bio-inline {
            margin-left: 1em;
            padding: 0;
        }
        
        p {
            margin: 0;
        }

        // draw empty gray circle for those without a profile pic
        .noprofile {
            fill: #f5f5f5;
            r: $profile-size / 2;
            cx: $profile-size / 2;
            cy: $profile-size / 2;
        }
    //}
}

// this is responsible for the small people circles under each project card
ul.project-people {
    display: inline;
    list-style-type: none;
    padding-left: 0em;
    
    li {        
        display: inline;
        
        a.person {
            font-weight: normal;
            color: $dark-gray;
            
            position: relative;
            text-align: center;
            vertical-align: top;
            display: inline-block;
            
            width: 2.2rem;
            height: 2.2rem;
    
            img {
                width: inherit;
                height: inherit;
            }
    
            .caption {
                display: none;
            }
        }   
    }  
}

.card.link:hover {
    border: solid 1px #CCC;
    -moz-box-shadow: 2px 2px 3px #999;
    -webkit-box-shadow: 2px 2px 3px #999;
    box-shadow: 2px 2px 3px #999;
}

/* Customize container */
@media (min-width: 960px) {
    .container {
        max-width: 1000px;
    }
  
    .card-columns { @include columns(3); }

    ul.grad { @include columns(2); }
}

@media (max-width: 768px) {
    .card-columns { @include columns(2); }
}

@media (max-width: 550px) {
    .card-columns { @include columns(1); }
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Jumbotron */
.jumbotron {
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron p {
    font-size: 15px;
}
.jumbotron {
    padding-top: 18px;
    padding-bottom: 10px;
}

/* Sampa logo */
#logo {
    width: 220px;
    //width: 154px;
    //height: 35px;
    margin: 2px 0 8px 0;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

/* Slightly better-looking header on mobile. */
@media screen and (max-width: 767px) {

    .nav-pills {
        display: block;
        margin: 0 auto 1em;
    }

    .nav-pills .nav-link {
        padding: .5em .4em;
    }
    
    #logo {
        clear: both;
    }
}

@media screen and (max-width: 475px){
    .header {
        padding-bottom: 50px;
    }
}

/* Photo */
.inset-image {
    width: 60%;
    margin: 0 auto;
}

/* People list */
dl.people dd {
    margin-bottom: 1em;
}

/* Front page news. */

ul.news {
    
    list-style-type: none;
    padding-left: 0em;
    
    .icon {
        color: #bbb;
        font-size: 2em;
        padding: 0rem 1rem;
        width: 4rem;
        text-align: center;
    }
        
    display: table;
    .text, .icon {
        display: table-cell;
        vertical-align: top;
    }
    
    .date {
        color: $dark-gray;
        font-weight: bold;
        display: block;
        font-size: 0.8em;
    }
}

//ul.news .date {
//    color: #999;
//    font-weight: bold;
//    display: block;
//}
//ul.news > li {
//    margin-top: 1em;
//}
//ul.news .shortnews .date {
//    float: left;
//    margin-right: 1em;
//}
//ul.news .bloglink a {
//    font-size: 1.4em;
//}

.illustration {
    float: right;
    margin: 0 0 1em 1em;
}

/********** research page ******/
#proj_header {
}

#proj-title {
}

#proj-title h3 {
    margin: 0;
}

#proj-people {
}
